import React from "react";
import { graphql, navigate } from "gatsby";
import tw, { css } from "twin.macro";

// ========= UTILS =========
import copy from "../assets/copy";
import { ENUMS } from "../libs/globals";

// ========= COMPONENTS =========
import NavBar from "../components/Elements/NavBar";

// ========= COMPONENTS =========
import missionBackgroundImage from "../assets/img/missionBG.png";
import penLogo from "../assets/img/PenLogoRed.png";
import { logEvent } from "../libs/auth";

const OurMission = ({ text }) => {
	return (
		<div
			css={[
				tw`
					lg:w-1/2
					lg:pr-10 mb-10 lg:mb-0
				`,
			]}
		>
			<p
				css={[
					tw`
						text-white text-lg
					`,
				]}
			>
				{text}
				<a
					css={[
						tw`
							text-white hover:text-red font-bold
							transition-colors duration-150 ease-in-out
							block
							mt-6 mb-10
						`,
					]}
					href="https://pen.org"
					target="_blank"
					rel="noreferrer"
				>
					Learn More at PEN.org
				</a>
			</p>
			<SocialMediaLinks />
		</div>
	);
};

const SocialMediaLinks = () => {
	return (
		<div
			css={[
				tw`
					flex flex-wrap
				`,
			]}
		>
			<a
				href="https://twitter.com/PENamerica"
				rel="noreferrer"
				target="_blank"
				onClick={() =>
					logEvent(`send_to_pen`, { social: `twitter` })
				}
			>
				<svg
					width="40"
					height="40"
					css={[
						tw`
							text-white fill-current hover:text-red transition-all duration-150 ease-in-out inline-block mr-5
						`,
					]}
				>
					<path d="M19.754.178c10.908 0 19.751 8.845 19.751 19.754 0 10.91-8.843 19.753-19.751 19.753C8.844 39.685 0 30.842 0 19.932S8.845.178 19.754.178zm3.974 14.614c-1.656 0-2.998 1.355-2.998 3.03 0 .238.027.47.078.69a8.478 8.478 0 01-6.179-3.166 3.045 3.045 0 00-.405 1.524c0 1.051.529 1.979 1.333 2.522a2.96 2.96 0 01-1.358-.379v.038a3.026 3.026 0 002.405 2.973 3 3 0 01-1.354.052 3.007 3.007 0 002.8 2.105 5.975 5.975 0 01-4.438 1.254 8.415 8.415 0 004.595 1.362c5.514 0 8.53-4.619 8.53-8.624 0-.13-.004-.262-.01-.392a6.11 6.11 0 001.496-1.57 5.921 5.921 0 01-1.72.479 3.038 3.038 0 001.317-1.678c-.58.349-1.221.6-1.904.737a2.974 2.974 0 00-2.188-.957z" />
				</svg>
			</a>
			<a
				href="https://www.facebook.com/PENamerica/"
				rel="noreferrer"
				target="_blank"
				onClick={() =>
					logEvent(`send_to_pen`, { social: `facebook` })
				}
			>
				<svg
					width="40"
					height="40"
					css={[
						tw`
							text-white fill-current hover:text-red transition-all duration-150 ease-in-out inline-block mr-5
						`,
					]}
				>
					<path d="M20.214 0c11.05 0 20.008 8.96 20.008 20.008 0 11.05-8.958 20.009-20.008 20.009-11.05 0-20.008-8.96-20.008-20.01C.206 8.96 9.164 0 20.214 0zm3.535 12.368h-2.326c-3.041 0-3.209 2.194-3.216 2.834v2.229h-1.529v2.573h1.529v7.643h3.139v-7.643h2.107s.197-1.234.293-2.583h-2.388v-1.76c0-.261.342-.615.68-.615h1.71v-2.678z" />
				</svg>
			</a>
			<a
				href="https://www.instagram.com/penamerica/"
				rel="noreferrer"
				target="_blank"
				onClick={() =>
					logEvent(`send_to_pen`, { social: `instagram` })
				}
			>
				<svg
					width="41"
					height="40"
					css={[
						tw`
							text-white fill-current hover:text-red transition-all duration-150 ease-in-out inline-block mr-5
						`,
					]}
				>
					<path d="M20.436 0c11.05 0 20.008 8.96 20.008 20.008 0 11.05-8.958 20.009-20.008 20.009-11.05 0-20.008-8.96-20.008-20.01C.428 8.96 9.386 0 20.436 0zm-3.3 12.058c-1.278.06-2.413.353-3.352 1.289-.939.935-1.228 2.07-1.292 3.351-.075 1.321-.075 5.28 0 6.6.06 1.282.353 2.417 1.292 3.352.939.935 2.07 1.228 3.352 1.292 1.32.075 5.28.075 6.6 0 1.281-.06 2.416-.353 3.351-1.292.936-.935 1.228-2.07 1.293-3.352.075-1.32.075-5.275 0-6.596-.061-1.282-.354-2.417-1.293-3.352-.935-.935-2.07-1.228-3.351-1.292-1.32-.075-5.28-.075-6.6 0zm3.302 1.385c1.163 0 3.665-.093 4.715.321a2.7 2.7 0 011.52 1.52c.418 1.054.322 3.552.322 4.716 0 1.164.096 3.666-.322 4.715a2.7 2.7 0 01-1.52 1.52c-1.053.418-3.552.322-4.715.322-1.164 0-3.666.093-4.716-.321a2.7 2.7 0 01-1.52-1.52c-.418-1.054-.321-3.552-.321-4.716 0-1.164-.093-3.666.32-4.715a2.7 2.7 0 011.521-1.52c1.053-.418 3.552-.322 4.716-.322zm0 2.456A4.095 4.095 0 0016.336 20a4.095 4.095 0 004.102 4.101 4.095 4.095 0 004.1-4.101 4.095 4.095 0 00-4.1-4.101zm0 1.435A2.669 2.669 0 0123.104 20c0 1.47-1.2 2.666-2.666 2.666A2.671 2.671 0 0117.77 20a2.669 2.669 0 012.667-2.666zm4.269-2.56a.957.957 0 100 1.914.957.957 0 000-1.914z" />
				</svg>
			</a>
			<a
				href="https://www.youtube.com/c/PENAmerica"
				rel="noreferrer"
				target="_blank"
				onClick={() =>
					logEvent(`send_to_pen`, { social: `youtube` })
				}
			>
				<svg
					width="41"
					height="40"
					css={[
						tw`
							text-white fill-current hover:text-red transition-all duration-150 ease-in-out inline-block mr-5
						`,
					]}
				>
					<path d="M20.658 0c11.05 0 20.007 8.96 20.007 20.008 0 11.05-8.957 20.009-20.007 20.009S.65 31.057.65 20.007C.65 8.96 9.608 0 20.658 0zm.045 14.168h-.316c-.586.004-2.04.017-3.479.074l-.505.022c-1.171.056-2.262.144-2.777.285a2.257 2.257 0 00-1.593 1.602c-.35 1.312-.374 3.962-.375 4.32v.082c.001.359.026 3.004.375 4.321.208.781.816 1.37 1.593 1.577.515.139 1.606.226 2.777.282l.505.021c1.608.063 3.235.072 3.65.073h.2c.37-.001 1.712-.008 3.142-.055l.507-.019c1.354-.053 2.693-.146 3.283-.307a2.225 2.225 0 001.592-1.577c.337-1.264.372-3.763.375-4.267v-.18c-.003-.503-.038-3.003-.375-4.271a2.257 2.257 0 00-1.592-1.602c-.59-.16-1.93-.253-3.283-.307l-.507-.019a112.106 112.106 0 00-2.972-.054h-.17l-.055-.001zm-1.885 3.664l4.708 2.675-4.708 2.676v-5.35z" />
				</svg>
			</a>
		</div>
	);
};

const AboutCampaign = ({ text }) => {
	return (
		<div
			css={[
				tw`
					lg:w-1/2
					lg:px-10
					mb-16 lg:mb-0 mt-16 lg:mt-0
					font-neutra
				`,
			]}
		>
			<h3
				css={[
					tw`
						text-white uppercase mb-2
					`,
				]}
			>
				{copy.mission.aboutCampaignHeadline}
			</h3>
			<p
				css={[
					tw`
						text-red text-3xl font-bold
					`,
				]}
			>
				{text}
			</p>
		</div>
	);
};

const Mission = ({ data }) => {
	return (
		<section
			css={[
				tw`
					w-full h-full min-h-screen lg:w-screen lg:h-screen
					bg-black bg-cover bg-center
					font-centra
				`,
				css`
					background-image: url("${missionBackgroundImage}");
				`,
			]}
		>
			<div
				css={[
					tw`
					container mx-auto
					p-10 xl:pl-64 lg:pl-48 lg:pr-0 lg:pt-32
				`,
				]}
			>
				<img
					alt="PEN America Logo"
					css={[
						tw`
							mb-4
							w-48
						`,
					]}
					src={penLogo}
				/>
				<div
					css={[
						tw`
							container lg:flex lg:flex-wrap
						`,
					]}
				>
					<OurMission
						text={
							data.sanitySiteCopy.ourMission
								?.mission ?? ``
						}
					/>
					<AboutCampaign
						text={
							data.sanitySiteCopy.ourMission
								?.aboutCampaign ?? ``
						}
					/>
				</div>
			</div>
			<NavBar activePage={ENUMS.NAV.MISSION} />
		</section>
	);
};

export default Mission;

export const query = graphql`
	query MissionQuery {
		sanitySiteCopy {
			ourMission {
				mission
				aboutCampaign
			}
		}
	}
`;
